import React, { useEffect, useState } from "react";
import TextfieldNumber from "../textfields/textfield.number";
import { styles } from "../../stylesheets/simulateurs.css";
import SelectCommon from "../selects/select.common";
import ButtonYesno from "../buttons/button.yesno.js";
import { useDispatch } from "react-redux";
import { setDMTGObject } from "../../actions/dmtg.action";
import { ToolTipPerso } from "../selects/tooltip_perso";
import TextfieldAbattement from "../textfields/textfield.abattement";
import { Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useSnackbar } from "notistack";

const Successions_Donataire = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [lien_parente_succession, setLien] = useState(1);
  const [conditions_particulieres, setConditions] = useState(0);
  const [propre_chef, setPropreChef] = useState(0);
  const [handicap, setHandicap] = useState(0);
  //MONTANT G
  const [montant_succession, setMontantSuccesion] = useState(0);
  const [abattement_legal, setAbattementLegal] = useState(0);
  const [montant_utilise, setMontantUtilise] = useState(0);
  const [dateDonation, setDateDonation] = useState(new Date());
  const [donationsAnterieures, setDonationsAnterieures] = useState(0);
  const [donationsData, setDonationsData] = useState([{}]);
  //MONTANT F
  const [montant_rapportable, setRapportable] = useState(0);
  const [total_succession, setTotalSuccession] = useState(props.total_succession || 0);
  let rappelFiscal;

  useEffect(() => {
    if (props.total_succession) {
      setTotalSuccession(props.total_succession);
    }
  }, [props.total_succession]);

  useEffect(() => {
    setInRedux(null, null); // Init le redux
  }, [props.simulateurid]);

  // useEffect pour calculer montant_succession chaque fois que montant_rapportable, total_succession ou donataires changent
  useEffect(() => {
    const newMontantSuccession = Math.ceil(
      (props.total_succession || 0) / (props.donataires || 0) - (montant_rapportable || 0)
    );
    setMontantSuccesion(newMontantSuccession);
    handleChange(setMontantSuccesion, newMontantSuccession);
  }, [montant_rapportable, props.total_succession, props.donataires]);

  useEffect(() => {
    if (donationsData && donationsData.length > 0) {
      const newAbattementUtilise = donationsData.reduce((sum, donation) => {
        return sum + (donation.montant || 0);
      }, 0);
      handleChange(setMontantUtilise, newAbattementUtilise)
    }
  }, [donationsData]);

  // useEffect pour ajuster l'abattement légal en fonction du lien de parenté et de la situation de handicap
  useEffect(() => {
    if (lien_parente_succession === "1" && handicap === 1) {
      setAbattementLegal("259 325");
    } else if (lien_parente_succession === "1") {
      setAbattementLegal("100 000");
    } else if (lien_parente_succession === "2" && handicap === 1) {
      setAbattementLegal("175 257");
    } else if (lien_parente_succession === "2") {
      setAbattementLegal("15 932");
    } else if ((lien_parente_succession === "3" || lien_parente_succession === "4") && handicap === 1) {
      setAbattementLegal("167 286");
    } else if (lien_parente_succession === "3" || lien_parente_succession === "4") {
      setAbattementLegal("7 967");
    } else if ((lien_parente_succession === "5" || lien_parente_succession === "6") && handicap === 1) {
      setAbattementLegal("160 919");
    } else if (lien_parente_succession === "5" || lien_parente_succession === "6") {
      setAbattementLegal("1 594");
    }
  }, [lien_parente_succession, handicap]);

  const handleChange = async (setter, value) => {
    if (setter === setDateDonation && donationsData.length > 0 && donationsData[0]["date"]) {
      if (value < donationsData[0]["date"]) {
        enqueueSnackbar("La date doit être postérieure à celle de la donation antèrieure", {
          variant: "error",
        });
        return;
      }
      rappelFiscal = new Date(value);
      rappelFiscal.setFullYear(rappelFiscal.getFullYear() - 15);
      if (donationsData[donationsData.length - 1]["date"]) {
        if (
          donationsData[donationsData.length - 1]["date"] <
          parseInt(moment(rappelFiscal.toString()).utc(true).format("x"))
        ) {
          enqueueSnackbar(
            "Vous aurez une ou plusieurs donations antérieures en dehors du rappel fiscal, merci de vérifier les données renseignées",
            {
              variant: "error",
            }
          );
          return;
        }
      } else {
        if (
          donationsData[donationsData.length - 2]["date"] <
          parseInt(moment(rappelFiscal.toString()).utc(true).format("x"))
        ) {
          enqueueSnackbar(
            "Vous aurez une ou plusieurs donations antérieures en dehors du rappel fiscal, merci de vérifier les données renseignées",
            {
              variant: "error",
            }
          );
          return;
        }
      }
    }
    // On ne modifie que la variable pertinente
    if (setter === setRapportable) {
      if (!props.total_rapportable || props.total_rapportable === 0) {
        enqueueSnackbar('Veuillez remplir d\'abord le montant rapportable total', {
          variant: 'error'
        });
        return;
      } else if (props.total_rapportable < value) {
        enqueueSnackbar('Veuillez saisir un montant égal ou inférieur au montant rapportable total', {
          variant: 'error'
        });
        return;
      } else {
        setRapportable(parseInt(value));
      }
    }
    if (setter === setTotalSuccession) {
      setTotalSuccession(parseInt(value));
    }
    if (setter === setMontantUtilise) {
      if (value > parseInt(abattement_legal.replace(/\s/g, ''))) {
        value = parseInt(abattement_legal.replace(/\s/g, ''));
      }
    }
    setter(value);
    setInRedux(setter, value);
  };

  const handleDonationChange = async (index, key, value) => {
    if (key === "date") {
      rappelFiscal = new Date(dateDonation);
      rappelFiscal.setFullYear(rappelFiscal.getFullYear() - 15);
      if (index === 0) {
        if (value > dateDonation) {
          enqueueSnackbar("La date doit être antèrieure à celle de la nouvelle donation", {
            variant: "error",
          });
          return;
        }
      } else {
        if (value > donationsData[index - 1]["date"]) {
          enqueueSnackbar("La date doit être antèrieure à celle de la donation précédente", {
            variant: "error",
          });
          return;
        }
      }
      if (value < parseInt(moment(rappelFiscal.toString()).utc(true).format("x"))) {
        enqueueSnackbar("La date est en dehors du rappel fiscal", {
          variant: "error",
        });
        return;
      }
    }
    const updatedDonations = [...donationsData];
    updatedDonations[index][key] = value;
    setDonationsData(updatedDonations);
    setInRedux(setDonationsData, updatedDonations);
  };

  const addDonation = () => {
    if (!donationsData[donationsData.length - 1]["date"] || !donationsData[donationsData.length - 1]["montant"]) {
      enqueueSnackbar("Merci de remplir les informations de la donation antèrieure avant d'en rajouter une autre", {
        variant: "error",
      });
      return;
    }
    const updatedDonations = [...donationsData, {}];
    setDonationsData(updatedDonations);
  };

  const removeDonation = () => {
    if (donationsData.length > 1) {
      const updatedDonations = [...donationsData];
      updatedDonations.pop();
      setDonationsData(updatedDonations);
      setInRedux(setDonationsData, updatedDonations);
    }
  };

  const texteAvecSautsDeLigne1 =
    "Être célibataire, veuf, divorcé ou séparé de corps et à la double condition : \n - d’être âgé de plus de cinquante ans ou infirme \n - et avoir été domicilié constamment chez le défunt pendant les 5 années qui ont précédé le décès.";

  const lignes1 = texteAvecSautsDeLigne1.split("\n");

  const texteAvecSautsDeLigne2 =
    "Sont taxés au tarif applicable entre frères et sœurs soit 35% et 45% lorsqu’ils viennent en représentation de leur parents. \nLorsqu’ils héritent de leur propre chef le taux de 55% est applicable. \nCette mesure est rétroactive au 1er janvier 2007.";

  const lignes2 = texteAvecSautsDeLigne2.split("\n");

  const texteDonationsAnterieures =
    "Indiquer le montant des donations rapportables par héritier, déterminé selon les règles des articles 860 et 860-1 du Code civil \n Toutes les donations en avancement de part successorale hors donation-partage, donations simples notariées ou dons manuels, quel que soit le régime fiscal, consenties par le défunt de son vivant à l’héritier.";

  const lignesDonationsAnterieures = texteDonationsAnterieures.split("\n");

  const montantRevenantHeritier = props.donataires > 1 ? Math.round(props.total_succession / props.donataires) : 0;

  function setInRedux(setter, value) {
    let object = {
      simulateurid: props.simulateurid,
      inputs: {},
    };

    Object.assign(object["inputs"], { montantRevenantHeritier: montantRevenantHeritier });

    if (donationsData[0].date != null) {
      Object.assign(object, {
        donations_anterieures: donationsData.map(({ montant, date }) => ({
          montant: parseFloat(montant),
          date: Date.parse(date),
        })),
      });
    }

    if (setter == null) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setLien) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(value) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setTotalSuccession) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(value) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setConditions) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(value) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setPropreChef) {
      if (value === 1) {
        handleChange(setLien, 4);
        Object.assign(object["inputs"], { lien_parente_succession: 4 });
      } else if (value === 0) {
        handleChange(setLien, 3);
        Object.assign(object["inputs"], { lien_parente_succession: 3 });
      }
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setHandicap) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(value) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setDateDonation) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (value !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(value.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setMontantSuccesion) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(value) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setMontantUtilise) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(value) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setDonationsAnterieures) {
      if (value === 0) {
        setDonationsData([{}]);
        object.donations_anterieures = [{}];
        Object.assign(object["inputs"], { montant_utilise: 0 });
      } else {
        object.donations_anterieures = donationsData;
        handleDonationChange(0, "date", Date.parse(dateDonation));
      }
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setDonationsData) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(montant_rapportable) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = value;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    if (setter === setRapportable) {
      Object.assign(object["inputs"], { lien_parente_succession: parseInt(lien_parente_succession) });
      Object.assign(object["inputs"], { conditions_particulieres: parseInt(conditions_particulieres) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montant_utilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { montant_rapportable: parseInt(value) });
      Object.assign(object["inputs"], { total_succession: parseInt(total_succession) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object["inputs"], {
          date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x")),
        });
      }
    }

    props.functionSetSoloResults(props.soloResultKey, object);
    dispatch(setDMTGObject(object));
  }

  const lienDonation = [
    {
      id: 1,
      name: "En ligne directe - Ascendants ou enfants",
    },
    {
      id: 2,
      name: "Entre frères et sœurs",
    },
    {
      id: 4,
      name: "Aux neveux et nièces",
    },
    {
      id: 5,
      name: "Entre collatéraux jusqu'au 4ème degré inclusivement",
    },
    {
      id: 6,
      name: "Entre parents au-delà du 4ème degré et non parents",
    },
  ];

 
  return (
    <div style={styles.cardFlexContainer}>

      <div style={styles.cardTitleDonataire}>Héritier {props.soloResultKey + 1}</div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Quel est leur lien de parenté ?</div>
        <SelectCommon
          items={lienDonation}
          defaultValue={"1"}
          nameField={"name"}
          optionValue={"id"}
          handleChange={handleChange}
          setter={setLien}
        />
      </div>

      {parseInt(lien_parente_succession) === 2 ? (
        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Conditions particulières ?
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignes1.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />
          </div>
          <ButtonYesno handleChange={handleChange} setter={setConditions} defaultValue={0} />
        </div>
      ) : (
        <></>
      )}

      {parseInt(lien_parente_succession) === 2 && parseInt(conditions_particulieres) === 1 ? (
        <div>
          <div style={styles.messageInABottle}>Le ou les bénéficaires sont exonérés de droits</div>
        </div>
      ) : (
        <></>
      )}

      {parseInt(lien_parente_succession) === 3 || parseInt(lien_parente_succession) === 4 ? (
        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Héritent-ils de leur propre chef ?
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignes2.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />
          </div>
          <ButtonYesno handleChange={handleChange} setter={setPropreChef} defaultValue={0} />
        </div>
      ) : (
        <></>
      )}

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          Le bénéficiaire est-il en situation de handicap ?
          <ToolTipPerso
            title={
              "Cas de l’héritier incapable de travailler dans des conditions normales de rentabilité, en raison d'une infirmité physique ou mentale, congénitale ou acquise (CGI, art. 779, BOI-ENR-DMTG-10-50-20, § 120 à 190)."
            }
          />
        </div>
        <ButtonYesno handleChange={handleChange} setter={setHandicap} defaultValue={0} />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Indiquer la date du décès</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={dateDonation}
            onChange={(val) => {
              handleChange(setDateDonation, val);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          Montant des donations, reçues par l'héritier, rapportables à la succession (de plus ou moins de 15 ans){" "}
          <ToolTipPerso
            title={
              <Typography style={{ fontSize: 14 }}>
                {lignesDonationsAnterieures.map((ligne, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {ligne}
                  </React.Fragment>
                ))}
              </Typography>
            }
          />
        </div>
        <TextfieldNumber
          handleChange={handleChange}
          type={"number"}
          placeholder={"Indiquer le montant"}
          setter={setRapportable}
        />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Masse à partager avec l'ensemble des héritiers</div>
        <TextfieldAbattement
          type={"number"}
          placeholder={props.total_succession.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"}
        />
      </div>

      {props.donataires > 1 ? (
        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Dont 1/{props.donataires} revenant à l'héritier</div>
          <TextfieldAbattement
            type={"number"}
            placeholder={montantRevenantHeritier.toLocaleString("en-EN").replace(/,/g, " ") + " €"}
          />
        </div>
      ) : (
        <></>
      )}

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Sous déduction du montant de son rapport</div>
        <TextfieldAbattement
          type={"number"}
          placeholder={(montant_rapportable || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"}
        />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Montant taxable revenant à l'héritier</div>
        <TextfieldAbattement
          type={"number"}
          placeholder={montant_succession.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"}
        />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Abattement légal</div>
        <TextfieldAbattement type={"number"} placeholder={abattement_legal + " €"} />
      </div>

      {/*DONATIONS ANTERIEURES*/}
      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          L'héritier a-t-il reçu une donation au cours des 15 dernières années ?
          <ToolTipPerso
            title={
              "Indiquer toutes les donations consenties par le défunt de son vivant à l’héritier dans les 15 dernières années, en avancement de part successorale ou hors part successorale, donations-partages, dons manuels hors dispositifs de faveur de type don familial de somme d’argent notamment."
            }
          />
        </div>
        <ButtonYesno handleChange={handleChange} setter={setDonationsAnterieures} defaultValue={0} />
      </div>

      {donationsAnterieures ? (
        <div>
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Part de l’abattement légal déjà utilisé au cours des 15 dernières années</div>
            <TextfieldAbattement
                type={"number"}
                placeholder={montant_utilise.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"}
            />
          </div>
          {donationsData.map((donation, index) => (
            <div key={index}>
              <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>Indiquer la date de la donation antérieure {index + 1}</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={donation.date}
                    onChange={(e) => {
                      void handleDonationChange(index, "date", parseInt(moment(e.toString()).utc(true).format("x")));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>
                  Montant de la donation antérieure {index + 1} (au jour de la donation)
                </div>
                <input
                  className={"input_field"}
                  type={"number"}
                  placeholder={"Indiquer le montant"}
                  onChange={(e) => {
                    void handleDonationChange(index, "montant", parseFloat(e.target.value));
                  }}
                />
              </div>
            </div>
          ))}
          <div>
            <div style={styles.buttonsArea}>
              <button style={styles.removeButton} type="button" onClick={() => removeDonation()}>
                Supprimer donation
              </button>
              <button style={styles.addButton} type="button" onClick={addDonation}>
                Ajouter donation
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Successions_Donataire;
