import React, {useState} from 'react';
import "./profile.css"
import ButtonTextSolo from "../../components/buttons/button_text_solo";
import useIsMountedRef from "../../utils/mountedRef";
import TextfieldProfile from "../../components/textfields/textfield.profile";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import UserCard from "../../assets/images/user_card_2.png"
import {API_BASE_URL} from "../../config";
import axios from "axios";
import {useSnackbar} from "notistack";
import Breadcrumb from '../../layouts/breadcrumb/breadcrumb';

function Profile() {
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        outline: 0
    };

    const [changePass, setChangePass] = useState("")
    const isMountedRef = useIsMountedRef();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {enqueueSnackbar} = useSnackbar();

    let currentUser = JSON.parse(localStorage.getItem("user"));

    let [textfieldCurrentPass, setTextfieldCurrentPass] = useState("")
    let [textfieldNewPassCheck, setTextfieldNewPassCheck] = useState("")
    let [textfieldNewPass, setTextfieldNewPass] = useState("")

    const handleChange = async (setter, value) => {
        setter(value);
    };

    function CheckPass() {
        if (textfieldNewPass === textfieldNewPassCheck)
            ChangePassword()
        else
            enqueueSnackbar('Vos mots de passes ne correspondent pas.', {
                variant: 'error'
            });
    }

    function ChangePassword() {
        let dataObject = {
            "login": currentUser.login,
            "password": textfieldCurrentPass,
            "new_password": textfieldNewPass
        }

        var config = {
            method: 'post',
            url: `${API_BASE_URL}/users`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': JSON.parse(localStorage.getItem("user"))?.jwt,
            },
            data: dataObject
        };
        axios(config)
            .then((response) => {
                if (response.status === 200) {
                    handleClose()
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data != null) {
                  if (error.response.data.error == "Other_user_connected" ) {
                    localStorage.removeItem("user")
                    localStorage.setItem("other_user_connected", "other_user_connected")
                    window.location.href = "/login";
                  }
                }
              });
    }


    return (
        <div>
        <Breadcrumb 
                  content={<div>/ Mon Compte</div>}
                  />
        <div className={"containerProfile"}>

            <div className={"container_card"}>
                <div className={"container_card_header"}>

                    <div className={"card_header_items"}>
                        <div className={"container_logo"}>
                            <img src={UserCard} width={"100%"}/>
                        </div>

                        <h2>
                            Mon Compte
                        </h2>
                    </div>
                </div>
                <div className={"container_card_body"}>


                    <div className={"container_body_infos"}>
                        <div className={"item_50 margin_ajust"}>
                            <TextfieldProfile editable={false} type={"text"} value={currentUser.lastname}
                                              label={"Nom"}
                            />
                        </div>

                        <div className={"item_50 margin_ajust"}>
                            <TextfieldProfile editable={false} type={"text"} value={currentUser.firstname}
                                              label={"Prénom"}/>
                        </div>

                        <div className={"item_50 margin_ajust"}>
                            <TextfieldProfile editable={false} type={"text"} value={currentUser.societe}
                                              label={"Société"}/>
                        </div>

                        {/*     */}
                        <div className={"item_50 margin_ajust"}>
                        </div>
                        {/*    */}

                        <div className={"trait"}/>
                    </div>


                    <div className={"container_body_password"}>

                        <div className={"item_50 margin_ajust margin_ajust_top"} onClick={handleOpen}>
                            <ButtonTextSolo
                                //handleChange={handleChange}
                                text={"Changer mon mot de passe"}
                                setter={setChangePass}
                                onClick={handleOpen}
                            />

                        </div>

                        {/*{*/}
                        {/*    changePass ?*/}
                        {/*        <div className={"item_50"}>*/}
                        {/*            <TextfieldNumber editable={false} type={"text"}/>*/}
                        {/*        </div>*/}
                        {/*        :*/}
                        {/*        <></>*/}
                        {/*}*/}
                    </div>


                </div>

            </div>

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2" className={"modal_title"}>
                        Modifier mon mot de passe actuel
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{mt: 2}}>

                        <div className={"modal_rule"}>
                            Tapez votre nouveau mot de passe à deux reprises puis valider pour le modifier.
                        </div>

                        <div className={"modal_body"}>

                            <div className={"modal_item"}>
                                <TextfieldProfile editable={true} type={"password"}
                                                  label={"Ancien mot de passe"}
                                                  handleChange={handleChange}
                                                  setter={setTextfieldCurrentPass}
                                />
                            </div>

                            <div className={"modal_item"}>
                                <TextfieldProfile editable={true} type={"password"}
                                                  label={"Nouveau mot de passe"}
                                                  handleChange={handleChange}
                                                  setter={setTextfieldNewPass}
                                />
                            </div>

                            <div className={"modal_item"}>

                                <TextfieldProfile editable={true} type={"password"}
                                                  label={"Vérification nouveau mot de passe"}
                                                  handleChange={handleChange}
                                                  setter={setTextfieldNewPassCheck}
                                />
                            </div>
                        </div>


                        <div onClick={((e) => {
                            CheckPass()
                        })}
                        >
                            <ButtonTextSolo
                                text={"Changer mon mot de passe"}
                                setter={setChangePass}
                            />
                        </div>
                    </Typography>
                </Box>
            </Modal>

        </div>
        </div>
    );
};

export default Profile;
