import React, {useState} from 'react';
import Colors from "../../useclass/colors";
import {API_BASE_URL} from "../../config";
import axios from "axios";

const TextSimulateurTitle = (props) => {
    const stylesTempo = {
        containerTitle: {
            color: Colors.primaryBlue,
            width: "90%",
            fontSize: 22,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 10,
            marginBottom: 10
        },
        containerBody: {
            width: "88%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        container: {
            width: "70vw",
            marginLeft: "auto",
            marginRight: "auto",
        },
        containerItem: {
            display: "grid",
            gridTemplateColumns: "32px 12px 90%",
            marginBottom: 35,
            paddingTop: 55,
            fontSize: 22,
            lineHeight: 1.5,
        },
        bar: {
            backgroundColor: Colors.primaryBlue,
            width: 3,
            height: "100%",
            marginTop: "auto",
            marginBottom: "auto"
        },
        containerStar: {
            marginTop: "auto",
            marginBottom: "auto"
        }
    }


    const [allFavoris, setAllFavoris] = useState(localStorage.getItem("favoris"))

    function onClickFavoris(id) {
        var config = {
            method: 'get',
            url: `${API_BASE_URL}/favoris/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': JSON.parse(localStorage.getItem("user"))?.jwt,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    localStorage.setItem("favoris", response.data)
                    if (response.data !== null)
                        setAllFavoris(JSON.parse(response.data))
                    else
                        setAllFavoris(response.data)
                }
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data != null) {
                  if (error.response.data.error == "Other_user_connected" ) {
                    localStorage.removeItem("user")
                    localStorage.setItem("other_user_connected", "other_user_connected")
                    window.location.href = "/login";
                  }
                }
              
              });
    }

    function returnStar(item) {
        if (allFavoris == null)
            return (<svg xmlns="http://www.w3.org/2000/svg" width="20.847" height="19.011"
                         preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                <path fill="none" stroke="#5E91B6" strokeLinejoin="round" strokeWidth="32"
                      d="M480 208H308L256 48l-52 160H32l140 96l-54 160l138-100l138 100l-54-160Z"/>
            </svg>)

        else {
            const valuesArray = Object.values(allFavoris);

            if (valuesArray.includes(item?.toString()))
                return (<svg xmlns="http://www.w3.org/2000/svg" width="20.847" height="19.011"
                             preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                        <path fill="#5E91B6"
                              d="M394 480a16 16 0 0 1-9.39-3L256 383.76L127.39 477a16 16 0 0 1-24.55-18.08L153 310.35L23 221.2a16 16 0 0 1 9-29.2h160.38l48.4-148.95a16 16 0 0 1 30.44 0l48.4 149H480a16 16 0 0 1 9.05 29.2L359 310.35l50.13 148.53A16 16 0 0 1 394 480Z"/>
                    </svg>
                )
            else
                return (<svg xmlns="http://www.w3.org/2000/svg" width="20.847" height="19.011"
                             preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                    <path fill="none" stroke="#5E91B6" strokeLinejoin="round" strokeWidth="32"
                          d="M480 208H308L256 48l-52 160H32l140 96l-54 160l138-100l138 100l-54-160Z"/>
                </svg>)
        }

    }


    return (
        <div style={stylesTempo.container}>
            <div style={stylesTempo.containerItem}>

                <div onClick={e => onClickFavoris(props.currentSimuId)} style={stylesTempo.containerStar}>
                    {
                        returnStar(props.currentSimuId)
                    }

                </div>

                <div style={stylesTempo.bar}>

                </div>
                <div style={stylesTempo.bodyText}>
                    {props.title}
                </div>
            </div>
        </div>
    );
};

export default TextSimulateurTitle;